import { FC, MouseEvent } from "react"
import { Button } from "@/ui/Button/Button"
import { ButtonProps } from "@/ui/Button/types"
import { GTAG_EVENTS, ROUTES } from "@/utils/constants"
import { gtagSend } from "@/utils/helpers"

const ButtonToCheckout: FC<
  ButtonProps & {
    totalCost?: number
    gtag?: {
      event: GTAG_EVENTS
    }
  }
> = ({
  size = "large",
  variant = "filled",
  as = "a",
  href = ROUTES.checkout,
  fullWidth = true,
  totalCost,
  disabled = false,
  onClick,
  ref,
  gtag,
  ...props
}) => {
  const isDisabled =
    disabled || (totalCost !== undefined ? totalCost <= 0 : false)

  const onClickHandle = (e: MouseEvent<HTMLButtonElement>) => {
    gtagSend({
      event: gtag?.event || GTAG_EVENTS.CLICK_ORDER,
    })
    if (!!onClick) {
      onClick(e)
    }
  }

  return (
    <Button
      as={as}
      href={href}
      variant={variant}
      size={size}
      disabled={isDisabled}
      fullWidth={fullWidth}
      onClick={onClickHandle}
      {...props}
    >
      Оформить заказ
    </Button>
  )
}

export { ButtonToCheckout }
