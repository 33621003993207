import type { BaseHTMLAttributes, FC } from "react"
import { memo } from "react"
import { StyledProductsGrid } from "./StyledProductsGrid"
import { ViewProductsType } from "./types"
import { Typography } from "@/ui/Typography/Typography"
import { VIEW_PRODUCTS_GRID } from "@/utils/constants"

export type ProductsPropsType = {
  view?: ViewProductsType
  withBorder?: boolean
  withoutContainerMargin?: boolean
  titleList?: string
}

export const Products: FC<
  ProductsPropsType & BaseHTMLAttributes<HTMLDivElement>
> = memo(
  ({
    titleList,
    view = VIEW_PRODUCTS_GRID,
    withBorder = false,
    withoutContainerMargin = false,
    children,
    ...props
  }) => {
    return (
      <StyledProductsGrid
        data-view={view}
        data-with-border={withBorder}
        data-without-container-margin={withoutContainerMargin}
        {...props}
      >
        {titleList && <Typography variant={"h3"}>{titleList}</Typography>}
        {children}
      </StyledProductsGrid>
    )
  },
)

Products.displayName = "Products"
